import React from "react";
import styled from "styled-components";
import { GoogleLogin } from '@react-oauth/google';

const StyledLoginPage = styled.div`
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	background-color: #e3b505;
	display:flex;
	flex-direction:column;
	justify-content:center;
	align-items:center;
`;

const QuoteSpan = styled.span`
	font-size:30px;
	font-family: "ZCOOL QingKe HuangYou", cursive;
	text-align:center;
	margin-bottom:30px;
	display:inline-block;
`

const ContentContainer = styled.div`

`;

const StartPage = ({ responseGoogle }) => {
	return (
		<StyledLoginPage>
			<ContentContainer>
				<QuoteSpan>
					<i className="fa fa-quote-left"></i>
					Hidden in a complex maze is mine name.
					<br />
					If thou art kind enough to help me,
					<br />
					then please find it by playing this game!
					<i className="fa fa-quote-right"></i>
					<br />
					<br />
					- Kittu
				</QuoteSpan>
			</ContentContainer>
			<div style={{}}></div>
			<GoogleLogin
				onSuccess={responseGoogle}
				onError={responseGoogle}
			/>
		</StyledLoginPage>
	);
};
export default StartPage;
