import React, { useState, useEffect } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import axios from 'axios';
import { isEmpty } from 'lodash';
import { CookiesProvider, useCookies } from 'react-cookie';
import LoginPage from "./components/LoginPage";
import Tetris from "./components/Tetris";
import "./styles.css";


export default function App() {
    const [isLoggedIn, setLoginStatus] = useState(false);

    const [cookies, setCookie] = useCookies(['login']);

    function onChange(token) {
        setCookie('login', token, { path: '/' });
    }

    const getAllUsers = async () => {
        const data = await axios.get(process.env.REACT_APP_API_URI + '/user/authenticated/getAll', {
            headers: {
                "x-token": cookies.login
            }
        });
        console.log(data);
    }


    const responseGoogle = async (response) => {
        const bodyObject = {
            authId: response.credential
        };
        try {
            if (isEmpty(response.errors)) {
                let response = await axios.post(process.env.REACT_APP_API_URI + '/login/user', bodyObject);
                onChange(response.data.token);
                setLoginStatus(true);
            }
        } catch (e) {
            console.log(e);
        }
    }


    const logout = async () => {
        try {
            await axios.get(process.env.REACT_APP_API_URI + '/logout/user');
            setLoginStatus(false);
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        async function getStatus() {
            console.log(cookies.login);
            try {
                const data = await axios.get(process.env.REACT_APP_API_URI + '/user/checkLoginStatus', {
                    headers: {
                        "x-token": cookies.login
                    }
                });
                if (isEmpty(data.error)) {
                    setLoginStatus(true);
                }
            } catch (e) {
                //console.log(e);
                setLoginStatus(false);
            }
        }
        getStatus();
    }, [])

    return (
        <CookiesProvider>
            <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID}>
                {
                    isLoggedIn ?
                    <Tetris></Tetris>
                    :
                    <LoginPage responseGoogle={responseGoogle}></LoginPage>
                }
            </GoogleOAuthProvider>
        </CookiesProvider>
    );
}